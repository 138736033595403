 
<template>
  <vx-card>
    <div>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="type">Type</label>
            <p id="type">{{logDetail.type?logDetail.type:"N/A"}}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="createdAt">Creation Time</label>
            <p id="createdAt">{{moment(logDetail.createdAt).format("DD/MM/YYYY HH:mm A")}}</p>
          </div>
        </vs-col>
      </vs-row>

      <h3 style="margin:0 10px 5px 15px">Enquirer Details</h3>
      <vs-row>
        
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="name">Buyer Name</label>
            <p id="name">{{logDetail.name ? logDetail.name : "N/A"}}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="email">Email</label>
            <p id="email">{{logDetail.email ? logDetail.email: "N/A"}}</p>
          </div>
        </vs-col>
         <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="email">Phone Number</label>
            <p id="email">{{logDetail.phoneNumber ? logDetail.phoneNumber: "N/A"}}</p>
          </div>
        </vs-col>

      </vs-row>

      <vs-row>
        <h3 style="margin:0px 10px 5px 15px">Dealer Details</h3>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <vx-tooltip text="View More Dealer Details" position="top" delay=".3s">
            <vs-button
              type="gradient"
              size="small"
              icon-pack="feather"
              @click="viewDealerHandler(logDetail.dealerId._id)"
              icon="icon-info"
              color="success"
            ></vs-button>
          </vx-tooltip>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="name">Dealer Name</label>
            <p id="name">{{logDetail.dealerId?logDetail.dealerId.name:"N/A"}}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="id">Stock Number</label>
            <p id="id">{{logDetail.carId.stockNumber?logDetail.carId.stockNumber:"N/A"}}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="displayAddress">Display Address</label>
            <p id="displayAddress">{{logDetail.dealerId?logDetail.dealerId.displayAddress:"N/A"}}</p>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="licenseNumber">License Number</label>
            <p id="licenseNumber">{{logDetail.dealerId?logDetail.dealerId.licenseNumber:"N/A"}}</p>
          </div>
        </vs-col>
        
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="contactName">Contact Name</label>
            <p id="contactName">{{logDetail.dealerId.contactInformation?logDetail.dealerId.contactInformation.contactName:"N/A"}}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="email">Contact Email</label>
            <p id="email">{{logDetail.dealerId.contactInformation?logDetail.dealerId.contactInformation.email:"N/A"}}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="contactPhone">Contact Mobile</label>
            <p id="contactPhone">{{logDetail.dealerId.contactInformation?logDetail.dealerId.contactInformation.contactPhone:"N/A"}}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="contactPhone">Contact Telephone</label>
            <p id="contactPhone">{{logDetail.dealerId.contactInformation?logDetail.dealerId.contactInformation.contactTelePhone:"N/A"}}</p>
          </div>
        </vs-col>
      </vs-row>

      <vs-row>
       <h3 style="margin:0px 10px 5px 15px">Car Details</h3>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <vx-tooltip text="View More Car Details" position="top" delay=".3s">
            <vs-button
              type="gradient"
              size="small"
              icon-pack="feather"
              @click="viewDetailHandler(logDetail.carId._id)"
              icon="icon-info"
              color="success"
            ></vs-button>
          </vx-tooltip>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="make">Make</label>
            <p id="licenseNumber">{{logDetail.carId.make}}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="model">Model</label>
            <p id="licenseNumber">{{logDetail.carId.model}}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="year">Year</label>
            <p id="licenseNumber">{{logDetail.carId.year}}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="badge">Badge</label>
            <p id="badge">{{logDetail.carId.badge?logDetail.carId.badge:"N/A"}}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="series">Series</label>
            <p id="series">{{logDetail.carId.series?logDetail.carId.series:"N/A"}}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="bodyType">Body Type</label>
            <p id="bodyType">{{logDetail.carId.bodyType?logDetail.carId.bodyType:"N/A"}}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="askingPrice">Asking Price</label>
            <p id="askingPrice">{{logDetail.carId.askingPrice}}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="kilometer">Kilometers</label>
            <p id="kilometer">{{logDetail.carId.kilometer}}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="transmission">Transmission</label>
            <p id="transmission">{{logDetail.carId.transmission?logDetail.carId.transmission:"N/A"}}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="engineCapacity">Engine Capacity</label>
            <p id="engineCapacity">{{logDetail.carId.engineCapacity?logDetail.carId.engineCapacity:"N/A"}}</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label for="cylinder">Cylinder</label>
            <p id="cylinder">{{logDetail.carId.cylinder?logDetail.carId.cylinder:"N/A"}}</p>
          </div>
        </vs-col>
      </vs-row>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "LogDetails",
  data() {
    return {
      logId: "",
      logDetail: {},
      activeTab: 0,
      back() {
        this.$router.push({ name: "LogLead" });
      },
    };
  },

  methods: {
    ...mapActions("logLead", ["fetchLogDetails"]),
    moment(date) {
      return moment(date);
    },
    viewDetailHandler(carId) {
      this.$router.push({ name: "VehicleDetails", params: { id: carId } });
    },
    viewDealerHandler(dealerId) {
      this.$router.push({ name: "CarDealerDetails", params: { id: dealerId } });
    },
    viewEnquirerHandler(userId) {
      this.$router.push({ name: "UserDetail", params: { id: userId } });
    },
    getLogDetail(id) {
      let self = this;
      this.fetchLogDetails(id).then((res) => {
        self.logDetail = res.data.data;
      });
    },
  },

  created() {
    this.logId = this.$route.params.id;
    this.getLogDetail(this.logId)
  },
};
</script> 
<style >
.ql-editor {
  min-height: 200px;
}
</style> 